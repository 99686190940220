<template>
  <div @click="openSidebar" class="cursor-pointer">
    <v-badge
      left
      overlap
      color="error"
      class="badge-notify"
      :content="countTotal"
      :value="countTotal"
    >
      <v-icon>fa-regular fa-bell</v-icon>
    </v-badge>
  </div>
</template>

<script>
import * as signalR from '@microsoft/signalr';
import store from '@/store';
import { mapGetters } from 'vuex';
import { wait } from '@/helpers/utils';
import { NotificationService } from '@/services/api/notification';

export default {
  data: () => ({
    connection: null,
    componentDestroyed: false
  }),

  created() {
    this.search();
    this.connectionHub();
  },

  async beforeDestroy() {
    await (this.componentDestroyed = true);

    if (this.connection) {
      this.connection.stop();
    }
  },

  computed: {
    ...mapGetters({
      notifications: 'notification/getNotifications',
      profile: 'authentication/getProfile'
    }),

    countWarning() {
      const messsages = this.notifications.warningNotifications.filter(
        x => x.read === false
      );

      return messsages.length;
    },

    countTask() {
      const messsages = this.notifications.taskNotifications.filter(
        x => x.read === false
      );

      return messsages.length;
    },

    countTotal() {
      return this.countWarning + this.countTask;
    }
  },

  methods: {
    openSidebar() {
      this.$store.commit('notification/openOrClosed');
    },

    async search() {
      try {
        const notificationService = new NotificationService();
        const { status, data } = await notificationService.getNotifications();

        if (status === 200) {
          this.$store.commit('notification/setNotifications', data);
        }
      } catch (error) {
        throw new Error(error);
      }
    },

    async connectionHub() {
      await wait(3000);

      if (this.profile && this.profile.id) {
        const url = `${process.env.VUE_APP_URL}/notification-hub?userId=${this.profile.id}`;

        this.connection = new signalR.HubConnectionBuilder()
          .withUrl(url)
          .configureLogging(signalR.LogLevel.Information)
          .build();

        try {
          await this.connection.start();
        } catch (error) {
          await wait(2000);
          this.connection.start();
        }

        this.connection.onclose(async function () {
          if (this.componentDestroyed === false) {
            await this.connection.start();
          }
        });

        if (this.connection) {
          this.connection.on('sendNotification', function (response) {
            store.commit('notification/setMessage', response);
          });
        }
      }
    }
  }
};
</script>
